import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AlertTitle from '@mui/material/AlertTitle';

export interface AlertModalProps {
  errorMessage: string;
  open: boolean;
  onClose?: () => void;
}

export default function AlertModal({
  open,
  errorMessage,
  onClose,
}: AlertModalProps): JSX.Element {
  const handleClose = (): void => {
    onClose?.();
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
