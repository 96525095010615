import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

export const useIsCurrentPath = (
  pattern: Parameters<typeof matchPath>[0],
  id: string,
): boolean => {
  const location = useLocation();

  const isCurrentPath = useBoolean(false);

  useEffect(() => {
    let doesPatternMatch = matchPath(pattern, location.pathname);

    if (doesPatternMatch == null && id === 'facilities') {
      doesPatternMatch = matchPath(`${id}/*`, location.pathname);
    }
    isCurrentPath.setValue(doesPatternMatch != null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPath.value, location.pathname, pattern]);

  return isCurrentPath.value;
};
