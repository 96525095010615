import type { RadioProps as MaterialUIRadioProps } from '@mui/material/Radio';
import MaterialUIRadio from '@mui/material/Radio';

import { StyledCheckedRadioIcon, StyledUncheckedRadioIcon } from './styles';

type RadioProps = Omit<MaterialUIRadioProps, 'checkedIcon' | 'color' | 'icon'>;

function Radio(props: RadioProps): JSX.Element {
  return (
    <MaterialUIRadio
      checkedIcon={<StyledCheckedRadioIcon />}
      color="default"
      icon={<StyledUncheckedRadioIcon />}
      {...props}
    />
  );
}

export default Radio;
