import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  PARSED_BOOLEAN_TUPPLE_LABELS,
  PARSED_BOOLEAN_TUPPLE_VALUES,
} from '@/constants/misc';
import { getRadioOptionList } from '@/utils/get-radio-option-list';
import type { UseFormSetValue } from 'react-hook-form';
import {
  BUSINESS_ACTIVITY_SECTION_ID,
  BUSINESS_ACTIVITY_SECTION_TITLE,
} from '../types';
import type { FormSchema } from './schema';

export const optionList = getRadioOptionList({
  labels: PARSED_BOOLEAN_TUPPLE_LABELS,
  values: PARSED_BOOLEAN_TUPPLE_VALUES,
});

export const THIRD_PARTY_PAYMENT_PAGE_ID = 'third_party_payment_page';

export class ThirdPartyPaymentData {
  static readonly THIRD_PARTY_PAYMENT: ThirdPartyPaymentData =
    new ThirdPartyPaymentData(
      'THIRD_PARTY_PAYMENT',
      'Are you a Third-Party Payment Processor?',
      'are-you-a-third-party-payment-processor',
      'Third Party Payment Processor',
    );

  private constructor(
    private readonly key: string,
    public readonly label: string,
    public readonly id: string,
    public readonly reviewLabel: string,
  ) {}

  toString(): string {
    return this.key;
  }
}

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const items: ItemDto[] = [];

  Object.values(ThirdPartyPaymentData).forEach(
    (item: ThirdPartyPaymentData) => {
      let answer = businessData[item.id] as string;
      if (item.id === ThirdPartyPaymentData.THIRD_PARTY_PAYMENT.id) {
        answer = (businessData[item.id] as string) === 'true' ? 'Yes' : 'No';
      }
      items.push({
        id: item.id,
        type: 'QUESTION',
        question: item.label,
        answer,
      });
    },
  );

  return {
    id: BUSINESS_ACTIVITY_SECTION_ID,
    title: BUSINESS_ACTIVITY_SECTION_TITLE,
    page: {
      id: THIRD_PARTY_PAYMENT_PAGE_ID,
      title: 'Business Activity Section - ATM Location',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setThirdPartyPaymentValues(
  thirdPartyPaymentSection: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  thirdPartyPaymentSection.page.items.forEach((item) => {
    if (item.id === ThirdPartyPaymentData.THIRD_PARTY_PAYMENT.id) {
      setValues(item.id, item.answer === 'Yes' ? 'true' : 'false');
    }
  });
}
