import * as z from 'zod';

import { PARSED_BOOLEAN_TUPPLE_VALUES } from '@/constants/misc';
import { ThirdPartyPaymentData } from './data';

export const formSchema = z.object({
  [ThirdPartyPaymentData.THIRD_PARTY_PAYMENT.id]: z.enum(
    PARSED_BOOLEAN_TUPPLE_VALUES,
  ),
});

export type FormSchema = z.infer<typeof formSchema>;
