import FormHelperText from '@mui/material/FormHelperText';

import type { InputStatus } from '../types';

import { inputStatusColor, inputStatusIcon } from '../utils';

import { StyledHelperTextContentWrapper } from './styles';
import { Box } from '@mui/material';

interface HelperTextProps {
  status: InputStatus;
  describedBy?: string;
  helperText?: string;
}

const HelperText = (props: HelperTextProps): JSX.Element => {
  const { status, describedBy, helperText } = props;

  return (
    <FormHelperText component="span" id={describedBy} sx={{ display: 'block' }}>
      {helperText == null ? (
        ' '
      ) : (
        <StyledHelperTextContentWrapper
          style={{ color: `var(${inputStatusColor[status]})` }}
        >
          <Box
            sx={{
              mr: 0.5,
              display: 'flex',
              minHeight: '1.5em',
              alignItems: 'center',
            }}
          >
            {inputStatusIcon[status]}
          </Box>
          {helperText}
        </StyledHelperTextContentWrapper>
      )}
    </FormHelperText>
  );
};

export default HelperText;
