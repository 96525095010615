import { styled } from '@mui/material/styles';

const UNCHECKED_BORDER_WIDTH = '0.1rem';

const ICON_SIZE = {
  height: '2rem',
  width: '2rem',
};

export const StyledUncheckedRadioIcon = styled('span')`
  ${ICON_SIZE};

  background-color: #fff;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
  border-radius: 50%;
  box-shadow: inset 0 0 0 ${UNCHECKED_BORDER_WIDTH} rgba(16, 22, 26, 0.2),
    inset 0 -${UNCHECKED_BORDER_WIDTH} 0 rgba(16, 22, 26, 0.1);

  .Mui-focusVisible & {
    outline: 0.2rem auto rgba(19, 124, 189, 0.6);
    outline-offset: 2;
  }

  input:disabled ~ & {
    background-color: rgba(206, 217, 224, 0.5);
    box-shadow: none;
  }
`;

export const StyledCheckedRadioIcon = styled(StyledUncheckedRadioIcon)`
  background-color: #d2d2d2;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0)
  );
  box-shadow: none;

  &:before {
    ${ICON_SIZE};

    background-image: radial-gradient(#254d73, #254d73 28%, transparent 32%);
    content: '';
    display: block;
  }
`;
